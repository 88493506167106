<template>
    <v-card>
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2"
        >
          mdi-email-outline
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="5"
              >
                <v-select
                  v-model="form.depId"
                  :label="$vuetify.lang.t('$vuetify.user.dep')"
                  :items="departments"
                  item-text="showName"
                  item-value="id"
                  clearable
                />
              </v-col>

              <v-col
                cols="3"
              >
                <v-menu
                  v-model="dateFromMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.dateFrom"
                      :label="$vuetify.lang.t('$vuetify.common.startDate')"
                      append-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="dateFrom = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.dateFrom"
                    @input="dateFromMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="3"
              >
                <v-menu
                  v-model="dateToMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.dateTo"
                      :label="$vuetify.lang.t('$vuetify.common.endDate')"
                      append-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="dateTo = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="form.dateTo"
                    @input="dateToMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="1"
              >
                <v-btn
                  class="mx-2 mt-2"
                  color="primary"
                  fab
                  dark
                  small
                  @click="handleSearch"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
    data() {
      return {
        dateFromMenu: false,
        dateToMenu: false,
        form: {
          dateFrom: null,
          dateTo: null,
          depId: null
        },
        depItems: []
      }
    },
    computed: {
      ...mapGetters({
        departments: 'department/departments'
      }),
    },
    created () {
      this.departmentQueryAll()
    },
    methods: {
      ...mapActions({
        departmentQueryAll: 'department/queryAll'
      }),
      handleSearch() {
        const data = {}
        for (let k in this.form) {
          if (this.form[k]) {
            data[k] = this.form[k]
          }
        }
        this.$emit('submit', data)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
